import React from "react";
import { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import pic1 from "../components/Images/CrimsonTech/CT_Banner.png";
import pic2 from "../components/Images/CrimsonTech/CT_Art.png";
import pic3 from "../components/Images/CrimsonTech/CT_Phonedata.png";
import pic4 from "../components/Images/CrimsonTech/CT_Persona1.png";
import pic5 from "../components/Images/CrimsonTech/CT_Persona2.png";
import pic6 from "../components/Images/CrimsonTech/CT_Amazon.png";
import pic7 from "../components/Images/CrimsonTech/CT_BestBuy.png";
import pic8 from "../components/Images/CrimsonTech/CT_Verizon.png";
import pic9 from "../components/Images/CrimsonTech/CT_AT&T.png";
import pic10 from "../components/Images/CrimsonTech/CT_Tmobile.png";
import pic11 from "../components/Images/CrimsonTech/CT_CompA.png";
import pic12 from "../components/Images/CrimsonTech/CT_Userflow.png";
import pic13 from "../components/Images/CrimsonTech/CT_Paper.png";
import pic14 from "../components/Images/CrimsonTech/CT_Wireframes.png";
import pic15 from "../components/Images/CrimsonTech/CT_Art2.png";
import haunted_decoder from "../components/Images/HauntedDecoderLogo.png";
import esilio_pizza from "../components/Images/EsilioLogo.png";
import deep_search from "../components/Images/CrimsonTech/deep_search.mp4";
import key_features from "../components/Images/CrimsonTech/key_features.mp4";
import product_videos from "../components/Images/CrimsonTech/product_videos.mp4";
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import "../components/styles/crimson_tech.css";

const CrimsonTech = () => {
  const [showText, setShowText] = useState(false);
  const [showPic, setShowPic] = useState(false);
  const onClick = () => setShowText(true);
  const onClose = () => setShowText(false);
  const onClick1 = () => setShowPic(true);
  const onClose1 = () => setShowPic(false);
  let images = [
    {
      url: pic6,
      title: "Amazon",
    },
    {
      url: pic7,
      title: "Best Buy",
    },
    {
      url: pic8,
      title: "Verizon",
    },
    {
      url: pic9,
      title: "AT&T",
    },
    {
      url: pic10,
      title: "T-Mobile",
    },
  ];
  const [showButton, setShowButton] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  return (
    <div className="fadeIn">
      {showButton && (
        <span onClick={scrollToTop} className="back-to-top">
          &#8679;
        </span>
      )}
      <div className="heroBanner my-3">
        <div>
          <img src={pic1} className="bg" alt="pic1"></img>
        </div>
        <div className="testBanner">
          <div className="overlayText d-flex justify-content-between align-items-center">
            <h5 className="p-5 mx-3">
              <span className="bold">Role:</span> Interaction Designer / Visual
              Designer/ UX Research / Frontend Developer
            </h5>
            <h5 className="p-5 mx-3">
              <span className="bold">Duration:</span> Sept. 2021 - Nov. 2021
            </h5>
            <a
              className="text-decoration-none"
              target="none"
              href="https://www.behance.net/gallery/131465265/Crimson-Tech"
            >
              {" "}
              <button className="mx-5 deckCT">Slide Deck</button>
            </a>
          </div>
        </div>
      </div>
      <div className="container_CT">
        <div className=" roleText row row-cols-1 row-cols-md-2 g-4 mt-5">
          <div className="col d-flex justify-content-center align-items-center p-5">
            <h5>
              <span className="bold">Role:</span> Interaction Designer / Visual
              Designer/ UX Research / Frontend Developer
            </h5>
          </div>
          <div className="col d-flex justify-content-center align-items-center p-5">
            <h5>
              <span className="bold">Duration:</span> Sept. 2021 - Nov. 2021
            </h5>
          </div>
        </div>
      </div>

      <div className="container">
        <div className=" d-flex justify-content-center align-items-center space">
          <img
            src={pic2}
            className="pic2"
            style={{ width: "100%", boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
            alt="pic2"
          ></img>
        </div>
      </div>
      <div className="container_CT">
        <div className="ProjectSummary spacebt mt-5">
          <div className="row row-cols-1 row-cols-md-1 g-4 text-center">
            <div className="col p-md-5">
              <h1 className="bold" style={{ color: "#DC143C" }}>
                Changing the way we see product information.
              </h1>
              <br></br>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-2 g-4 mt-5">
            <div className="col p-md-5">
              <h2 className="bold">Project Overview</h2>
              <p>
                Crimson Tech modernizes e-commerce tech with features that help
                consumers find the right product and get educated on technology.
              </p>
            </div>
            <div className="col p-md-5">
              <h2 className="bold">The Problem</h2>
              <p>
                With current e-commerce tech sites, product information is
                either not detailed or not enough to make a good buying
                decision. Crimson Tech aims to fix this by bringing enhanced
                product information visible to users across their buying
                journey.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container_CT">
        <div className="TheResearch  bg-white">
          <div className="row row-cols-1 row-cols-md-2 g-4">
            <div className="col p-5">
              <h2 className="bold " style={{ color: "#DC143C" }}>
                Understand the User
              </h2>

              <p>
                I needed to understand people's buying behavior to see how
                product information is utilized in their shopping journey. I
                decided to survey 20 participants and interviewed three people
                in my network that frequently shopped online for tech products.
              </p>
              <br></br>
              <p>
                For the survey, I asked questions specifically about product
                research and where exactly they get their information. During
                the interviews, I kept the questions open enough for
                participants to elaborate on their shopping journey.
              </p>
            </div>
            <div className="col p-5">
              <h2 className="bold" style={{ color: "#DC143C" }}>
                Key Questions
              </h2>
              <p>(1) Who is our user?</p>
              <p>(2) What is the users buying behavior?</p>
              <p>
                (3) Where do users find good product information to help them
                make a buying decision?
              </p>
              <p>
                (4) How can we make product information easy to understand for
                users?
              </p>
            </div>
          </div>
          <h2 className="spacetp text-center bold">Survey Results</h2>
          <div className="row row-cols-1 row-cols-md-3 spacebt g-4">
            <div className="col text-center p-5">
              <h1 className="bold" style={{ color: "#DC143C" }}>
                60%
              </h1>
              <p>
                of respondents don't utilize product information from e-commerce
                sites.
              </p>
            </div>
            <div className="col text-center p-5">
              <h1 className="bold" style={{ color: "#DC143C" }}>
                75%
              </h1>
              <p>
                of respondents said that looking at the specifications of a
                product is very important to them.{" "}
              </p>
            </div>
            <div className="col text-center p-5">
              <h1 className="bold" style={{ color: "#DC143C" }}>
                60%
              </h1>
              <p>
                of respondents said that watching videos is their main source of
                getting product information.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="PhoneResearch space">
          <div className="row row-cols-1 row-cols-md-1 g-4">
            <div className="col text-center">
              <h1 className="bold" style={{ color: "#DC143C" }}>
                Evaluating the Smartphone
              </h1>
              <p className="p-md-5">
                {" "}
                I decided to understand a particular product the smartphone and
                see what features are important. What is the deciding factor in
                buying a new smartphone? Based on Statista, longer battery life
                is the most important feature from American smartphone owners.
                Which is slightly ahead of usability features, durability,
                storage, and camera quality. This data helps me understand what
                features are considered when deciding which smartphone to buy.
              </p>
            </div>
            <div className="col p-md-5">
              <div className=" d-flex justify-content-center align-items-center">
                <img
                  src={pic3}
                  className="pic3"
                  style={{ width: "100%" }}
                  alt="pic3"
                ></img>
              </div>
            </div>
          </div>
        </div>
        <div className="Persona spacebt">
          <div className="row row-cols-1 row-cols-md-1 g-4">
            <div className="col  text-center">
              <h1 className="bold" style={{ color: "#DC143C" }}>
                Meet the Users
              </h1>
            </div>
            <div className="col p-md-5">
              <div className=" d-flex justify-content-center align-items-center">
                <img
                  src={pic4}
                  className="pic3"
                  style={{ width: "100%", borderRadius: "15px" }}
                  alt="pic3"
                ></img>
              </div>
            </div>
            <div className="col p-md-5 mt-5">
              <div className=" d-flex justify-content-center align-items-center">
                <img
                  src={pic5}
                  className="pic3"
                  style={{ width: "100%", borderRadius: "15px" }}
                  alt="pic3"
                ></img>
              </div>
            </div>
          </div>
        </div>
        <div className="Competitive Analysis space bg-white">
          <div className="row row-cols-1 row-cols-md-1 g-4">
            <div className="col p-5 text-center">
              <h1 className="bold" style={{ color: "#DC143C" }}>
                Competitive Analysis
              </h1>
              <p className="p-md-5">
                Now that I have background knowledge of customer buying behavior
                and notable factors in smartphone considerations. I looked at
                the existing competition to see what they are offering. I looked
                at five major e-commerce brands that sell specifically
                smartphones to see how well information is displayed.
              </p>
              <br></br>
              <p>Click on the link to view competitive analysis slides</p>
            </div>
            <div className="col text-center">
              <h5
                style={{ color: "#DC143C", cursor: "pointer" }}
                onClick={onClick}
              >
                View Slides
              </h5>
              {showText ? <Lightbox images={images} onClose={onClose} /> : null}
            </div>
            <div className="col p-md-5 mt-5">
              <div className=" d-flex justify-content-center align-items-center">
                <img
                  src={pic11}
                  className="pic3"
                  style={{ width: "100%", borderRadius: "15px" }}
                  alt="pic3"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className="UserFlow space">
          <div className="row row-cols-1 row-cols-md-1 g-4">
            <div className="col p-2 text-center">
              <h1 className="bold" style={{ color: "#DC143C" }}>
                User Flow
              </h1>
              <p className="p-md-5">
                I created a simple user flow where users first check out all
                smartphones and then participate in a search phone feature that
                would help them choose a phone based on their uses. The users
                then proceed to the product page. Here they view more
                information about the product.
              </p>
              <p>Click on the image to enlarge</p>
            </div>
            <div className="col mt-5 p-md-5">
              <div className=" d-flex justify-content-center align-items-center">
                <img
                  src={pic12}
                  className="pic12"
                  style={{ width: "100%", borderRadius: "15px" }}
                  alt="pic3"
                  onClick={onClick1}
                ></img>
                {showPic ? <Lightbox image={pic12} onClose={onClose1} /> : null}
              </div>
            </div>
          </div>
        </div>

        <div className="Design space">
          <div className="row row-cols-1 row-cols-md-1 g-4">
            <div className="col p-2 text-center">
              <h1 className="bold" style={{ color: "#DC143C" }}>
                Starting the Design
              </h1>
              <p className="p-md-5">
                Here I created sketches to layout the e-commerce design. I
                wanted to get a feel of the core features of the app before I
                move on to digital wireframes and prototypes.
              </p>
            </div>
            <div className="col mt-5 p-md-5">
              <div className=" d-flex justify-content-center align-items-center">
                <img
                  src={pic13}
                  className="pic13"
                  style={{ width: "100%", borderRadius: "15px" }}
                  alt="pic3"
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className="Digital_Wireframes space">
          <div className="row row-cols-1 row-cols-md-1 g-4">
            <div className="col p-2 text-center">
              <h1 className="bold" style={{ color: "#DC143C" }}>
                Digital Wireframes
              </h1>
            </div>
            <div className="col mt-5 p-md-5">
              <div className=" d-flex justify-content-center align-items-center">
                <img
                  src={pic14}
                  className="pic14"
                  style={{ width: "100%", borderRadius: "25px" }}
                  alt="pic3"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className=" d-flex justify-content-center align-items-center space">
          <img
            src={pic15}
            className="pic2"
            style={{ width: "100%", boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
            alt="pic2"
          ></img>
        </div>
        <div className="Key_Features space bg-white">
          <div className="col spacebt text-center">
            <h1 className="bold" style={{ color: "#DC143C" }}>
              Key Features
            </h1>
          </div>
          <div className="row spacebt">
            <div className="col-lg-8 p-md-5 p-4">
              <video
                playsInline="playsinline"
                autoPlay="autoplay"
                muted
                loop="loop"
              >
                <source src={deep_search} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="col-lg-4 p-5">
              {" "}
              <h3 className="bold" style={{ color: "#DC143C" }}>
                Deep Search
              </h3>
              <p>
                Deep search is an intuitive AI-driven tool that finds the
                perfect smartphones based on personal utility and ties it to
                technical specifications for a personalized, improved user
                experience. Search questions are specifically chosen based on
                user input. Using an AI approach, results are accurately
                depicted so that users can make a good buying decision.
              </p>
            </div>
          </div>
          <div className="row spacebt">
            <div className="col-lg-4 p-5">
              {" "}
              <h3 className="bold" style={{ color: "#DC143C" }}>
                User Friendly Specifications
              </h3>
              <p>
                Instead of listing out important specifications of a product, I
                try to create a similar approach to Best Buy. A
                better-visualized specification that indicates if it's low-end
                or high-end and a brief description explaining why. Unlike, the
                competitor I displayed key features that consumers most likely
                would be interested in such as battery, processor, or display.
              </p>
            </div>
            <div className="col-lg-8 p-md-5 p-4">
              <video
                playsInline="playsinline"
                autoPlay="autoplay"
                muted
                loop="loop"
              >
                <source src={key_features} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 p-md-5 p-4">
              <video
                playsInline="playsinline"
                autoPlay="autoplay"
                muted
                loop="loop"
              >
                <source src={product_videos} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="col-lg-4 p-5">
              {" "}
              <h3 className="bold" style={{ color: "#DC143C" }}>
                Product Videos
              </h3>
              <p>
                Product videos play a significant role in providing valuable
                information to customers. I thought of creating a video-sharing
                platform like Youtube for e-commerce. People can upload videos
                related to the product explaining key features and recommend
                items that pair well with the product. This platform is a unique
                opportunity for customers to get educated about the products,
                make confident buying decisions, and explore new products.
              </p>
            </div>
          </div>
        </div>
        <div className="Prototype">
          <div className=" spacetp mb-5 text-center">
            <h1 className="bold" style={{ color: "#DC143C" }}>
              Prototype
            </h1>
          </div>
          <div className="d-flex justify-content-center">
            <iframe
              title="proto"
              height="700px"
              width="1100px"
              frameBorder="0"
              scrolling="no"
              src="https://xd.adobe.com/embed/079d3af5-d524-4598-862e-ee2ac1481846-52ce/"
            ></iframe>
          </div>
        </div>
      </div>

      <div className="container_CT">
        <div className="Reflection space">
          <div className="row row-cols-1 row-cols-md-1 g-4 mb-5">
            <div className="col p-md-5 d-flex justify-content-center align-items-center">
              <div>
                <h1 className="bold text-center" style={{ color: "#DC143C" }}>
                  Reflection
                </h1>
              </div>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-2 g-4 mt-5">
            <div className="col p-md-5 ">
              <h2 className="bold">What I learned</h2>
              <p>
                This whole project was fun and challenging. I learned a lot
                about the consumer decision process through my research which
                helped me make user-centered designs. I learned new skills in
                Adobe XD which helped me quickly wireframe and prototype my
                work. Overall, I was passionate about this project from start to
                finish and especially loved how the design choices were based on
                the users.
              </p>
            </div>
            <div className="col p-md-5">
              <h2 className="bold">Next Steps</h2>
              <p>
                {" "}
                I want to closely look into the features and see how it can be
                expanded. A video creator program for e-commerce tech is very
                interesting. How can we leverage the structure of a video
                sharing platform to inform customers about product information
                and promote product awareness? It is necessary to define the
                core principles to fully flesh out the idea of product videos
                and its impacts.<br></br>
                Also, in the AI search feature, we can get valuable analytical
                insights that can help design specific recommendations for users
                that help find products best suited for them.
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-evenly text-center spacebt">
          <div>
            <p>Check out my full design process here</p>
            <a
              className="text-decoration-none"
              target="none"
              href="https://www.behance.net/gallery/131465265/Crimson-Tech"
            >
              {" "}
              <button className="mx-5 deckCT">Slide Deck</button>
            </a>
          </div>
          <div className="mt-5 mt-sm-0">
            <p>Check out the website build</p>
            <a
              className="text-decoration-none"
              target="none"
              href="https://crimsontech.netlify.app/"
            >
              {" "}
              <button className="mx-5 deckCT">Website</button>
            </a>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="text-center bg-white space">
          <h2>Let's Connect</h2>
          <p>
            Looking for a designer that have skills in development. Reach Out!
          </p>
          <div className="d-flex justify-content-center mt-5">
            <a
              className="text-decoration-none"
              href="https://www.linkedin.com/in/alan-eappen-a331571a0/"
              target="_blank"
              rel="noreferrer"
            >
              <i
                className="fab fa-linkedin mx-2"
                style={{ color: "#DC143C", fontSize: "200%" }}
              ></i>
            </a>
            <a
              className="text-decoration-none"
              href={"mailto:eappenalan123@gmail.com"}
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <i
                className="far fa-envelope mx-2"
                style={{ color: "#DC143C", fontSize: "200%" }}
              ></i>
            </a>
            <a
              className="text-decoration-none"
              href="https://www.behance.net/alaneappen"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <i
                className="fab fa-behance mx-2"
                style={{ color: "#DC143C", fontSize: "200%" }}
              ></i>
            </a>
            <a
              className="text-decoration-none"
              href="https://github.com/alaneappen514"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <i
                className="fab fa-github mx-2"
                style={{ color: "#DC143C", fontSize: "200%" }}
              ></i>
            </a>
          </div>
          <div className="spacetp">
            <div>
              <h3 className="bold">My Work</h3>
            </div>
            <div className="row row-cols-1 row-cols-md-2 g-4 mt-5">
              <div className="col  d-flex justify-content-center">
                <Card
                  className="feature_work"
                  style={{ width: "22rem", border: " none" }}
                >
                  <Card.Img
                    style={{
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                    variant="top"
                    src={haunted_decoder}
                  />
                  <Card.Body
                    style={{
                      backgroundColor: "#f5f5f5",
                      borderBottomLeftRadius: "20px",
                      borderBottomRightRadius: "20px",
                    }}
                  >
                    <Card.Text>
                      Check out a game I made from Unity.<br></br>Try playing
                      it!!
                    </Card.Text>
                    <Link
                      to="/haunted_decoder"
                      className="text-decoration-none"
                    >
                      {" "}
                      <button className="mx-5 deckCT">View</button>
                    </Link>
                  </Card.Body>
                </Card>
              </div>
              <div className="col  d-flex justify-content-center">
                <Card
                  className="feature_work"
                  style={{ width: "22rem", border: " none" }}
                >
                  <Card.Img
                    style={{
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                    variant="top"
                    src={esilio_pizza}
                  />
                  <Card.Body
                    style={{
                      backgroundColor: "#f5f5f5",
                      borderBottomLeftRadius: "20px",
                      borderBottomRightRadius: "20px",
                    }}
                  >
                    <Card.Text>
                      Have you ordered Pizza from a chatbot ? Check it out.
                    </Card.Text>
                    <Link
                      to="/esilio_pizzeria"
                      className="text-decoration-none"
                    >
                      {" "}
                      <button className="mx-5 deckCT">View</button>
                    </Link>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrimsonTech;
