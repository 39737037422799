import React from "react";
import "../components/styles/navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  function uncheck() {
    document.getElementById("test1").checked = false;
  }
  window.addEventListener(
    "resize",
    function () {
      if (window.matchMedia("(min-width: 768px)").matches) {
        document.getElementById("test1").checked = false;
      }
    },
    true
  );

  return (
    //Some text that I wrote here
    <div>
      <div className="navbar px-5 pt-4">
        <Link to="/home" style={{ textDecoration: "none" }}>
          <div className="logo"></div>
        </Link>

        <div className="menu-wrap">
          <input id="test1" type="checkbox" className="toggler" />
          <div className="hamburger">
            <div></div>
          </div>
          <div className="menu">
            <div>
              <div>
                <ul>
                  <li>
                    <Link
                      onClick={uncheck}
                      to="/home"
                      style={{ textDecoration: "none" }}
                    >
                      Portfolio
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={uncheck}
                      to="/about"
                      style={{ textDecoration: "none" }}
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <a
                      href="DesignResume2021.pdf"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      Resume
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="links d-flex">
          <Link
            className="mx-4 left"
            to="/home"
            style={{ textDecoration: "none" }}
          >
            Portfolio
          </Link>
          <Link className="left" to="/about" style={{ textDecoration: "none" }}>
            About
          </Link>
          <a className="mx-4 left" href="#" style={{ textDecoration: "none" }}>
            Resume
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
