import { Switch, Route, BrowserRouter } from "react-router-dom";
import Home from "../components/Home";
import About from "../components/About";
import HauntedDecoder from "../components/HauntedDecoder";
import EsilioPizzeria from "../components/EsilioPizzeria";
import CrimsonTech from "../components/CrimsonTech";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ScrolltoTop from "../components/ScrolltoTop";
import Gamecord from "../components/Gamecord";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <ScrolltoTop />
        <Switch>
          <Route exact path="/home" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/haunted_decoder" component={HauntedDecoder} />
          <Route exact path="/esilio_pizzeria" component={EsilioPizzeria} />\
          <Route exact path="/crimson_tech" component={CrimsonTech} />
          <Route exact path="/gamecord" component={Gamecord} />
          <Route path="*" component={Home} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
