import React from 'react'
import { useEffect, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import "../components/styles/e_pizza.css"
import pic1 from '../components/Images/EsilioPizzeria/EsilioBanner.png'
import pic2 from '../components/Images/EsilioPizzeria/EsilioPic2.png'
import pic3 from '../components/Images/EsilioPizzeria/EsilioPic3.png'
import persona1 from '../components/Images/EsilioPizzeria/Persona1.png'
import persona2 from '../components/Images/EsilioPizzeria/Persona2.png'
import pic4 from '../components/Images/EsilioPizzeria/EsilioPic4.png'
import pic5 from '../components/Images/EsilioPizzeria/EsilioPic5.png'
import pic6 from '../components/Images/EsilioPizzeria/EsilioPic6.png'
import pic7 from '../components/Images/EsilioPizzeria/EsilioPic7.png'
import pic8 from '../components/Images/EsilioPizzeria/EsilioPic8.png'
import feature1 from '../components/Images/EsilioPizzeria/Feature1.png'
import feature2_1 from '../components/Images/EsilioPizzeria/Feature2(1).png'
import feature2_2 from '../components/Images/EsilioPizzeria/Feature2(2).png'
import feature2_3 from '../components/Images/EsilioPizzeria/Feature2(3).png'
import feature3_1 from '../components/Images/EsilioPizzeria/Feature3(1).png'
import feature3_2 from '../components/Images/EsilioPizzeria/Feature3(2).png'
import feature3_3 from '../components/Images/EsilioPizzeria/Feature3(3).png'
import haunted_decoder from '../components/Images/HauntedDecoderLogo.png'
import crimson_tech from '../components/Images/CrimsonTech/CT_Logo.png'


const EsilioPizzeria = () => {

    const [showButton, setShowButton] = useState(false);


    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };


    // This function will scroll the window to the top 
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility)
        }
    }, []);
    return (
        <div className="fadeIn">
            {showButton && (
                <span onClick={scrollToTop} className="back-to-top">
                    &#8679;
                </span>
            )}
            <div className="heroBanner my-3">
                <div>
                    <img src={pic1} className='bg' alt='pic1'>
                    </img>
                </div>
                <div className="testBanner">
                    <div className="overlayText d-flex justify-content-between align-items-center">
                        <h5 className='p-5 mx-3'><span className='bold'>Role:</span>  Interaction Designer / Visual Designer/ UX Research</h5>
                        <h5 className='p-5 mx-3'><span className='bold'>Duration:</span> June 2021 - August 2021</h5>
                        <a className="text-decoration-none" target='none' href="https://www.behance.net/gallery/127924787/Esilio-Pizzeria"> <button className="mx-5 deck">Slide Deck</button></a>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className=' roleText row row-cols-1 row-cols-md-2 g-4 mt-5'>
                    <div className="col d-flex justify-content-center align-items-center p-5">
                        <h5><span className='bold'>Role:</span>  Interaction Designer / Visual Designer/ UX Research</h5>
                    </div>
                    <div className="col d-flex justify-content-center align-items-center p-5">
                        <h5><span className='bold'>Duration:</span> June 2021 - August 2021</h5>
                    </div>
                </div>
                <div className="ProjectOverview spacebt mt-5">
                    <div className="row row-cols-1 row-cols-md-2 g-4">
                        <div className="col p-5">
                            <h2 className="bold">Project Overview</h2>
                            <p>Esilio Pizzeria is a chatbot food delivery app created to simplify and personalize the food ordering process.
                                Users can conveniently order pizzas and customize them for delivery or pickup.
                            </p>
                        </div>
                        <div className="col p-5">
                            <h2 className="bold">Problem</h2>
                            <p>Many people order food through food delivery apps because they have a busy schedule or don’t have time to cook. However, most apps feel complicated in the food ordering process.</p>
                            <h2 className="bold">Goal</h2>
                            <p>Design an app the would simplify and personalize
                                the food ordering process.</p>
                        </div>
                    </div>

                    <div className=" d-flex justify-content-center align-items-center my-5">
                        <img src={pic2} className="pic2" style={{ width: '100%' }} alt='pic2'></img>
                    </div>
                </div>

                <div className="TheUser space bg-white">
                    <div className="row row-cols-1 row-cols-md-2 g-4">
                        <div className="col p-5">
                            <h2 className="bold">Understand the User</h2>
                            <p>I conducted interviews, sent out surveys, and created empathy maps to understand the user.
                                My target group was working adults and college students who have a busy schedule and can’t balance work with meal planning.
                            </p>
                            <br>
                            </br>
                            <p>
                                From this group, I documented their ways of ordering food. I also asked them to do simple tasks in a food delivery app to see how smooth the food ordering process would be.
                                Most struggled to get some parts finished.
                                However, they succeeded in accomplishing the goal eventually.
                                I assumed many people who are not tech-savvy would have difficulty ordering food online.
                            </p>
                        </div>
                        <div className="col p-5">
                            <h2 className="bold">Key Questions</h2>
                            <p>(1) Who is our user ?</p>
                            <p>(2) What is that user's goal ?</p>
                            <p>(1) How can we simplify a process so that all users can easily accomplish that goal ?</p>
                        </div>
                    </div>

                    <div className=" d-flex justify-content-center align-items-center">
                        <img src={pic3} className="pic3" style={{ width: '100%' }} alt='pic3'></img>
                    </div>
                </div>

                <div className="PainPoints space">
                    <div className="text-center">
                        <h2 className="bold p-5">Pain Points</h2>
                        <p>Here are some common pain points I noticed from my user research.</p>
                    </div>
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        <div className="col p-5">
                            <div className="circle_dot my-2 text-white text-center">1</div>
                            <div className="mt-2">Working adults and college students have a busy schedule. It’s hard to balance responsibilities with meal planning.</div>
                        </div>
                        <div className="col p-5">
                            <div className="circle_dot my-2 text-white text-center">2</div>
                            <div className="mt-2">Dissatisfied ordering food at a family restaurant because the waiter/waitress was very  busy helping others and didn’t receive a personalized visit.</div>
                        </div>
                        <div className="col p-5">
                            <div className="circle_dot my-2 text-white text-center">3</div>
                            <div className="mt-2">Food delivery apps are very confusing. There are so many screens to go through and buttons to click. The overall experience wasn't good and placing an order took time.</div>
                        </div>
                    </div>
                </div>

                <div className="Personas spacebt">
                    <div className="text-center">
                        <h2 className="bold p-5">Personas</h2>
                    </div>
                    <div className="row row-cols-1 row-cols-md-2 g-4">
                        <div className="col p-5">
                            <img src={persona1} className="pic3" style={{ width: '100%' }} alt='pic3'></img>
                        </div>
                        <div className="col p-5">
                            <img src={persona2} className="pic3" style={{ width: '100%' }} alt='pic3'></img>
                        </div>
                    </div>
                </div>

                <div className="Information_Architecture bg-white space">
                    <div className="text-center">
                        <h2 className="bold p-5">Information Architecture</h2>
                    </div>
                    <div className="row row-cols-1 row-cols-md-1 g-4">
                        <div className="col IA_Pic p-5">
                            <img src={pic4} className="pic4" style={{ width: '100%' }} alt='pic4'></img>
                        </div>
                    </div>
                </div>

                <div className="PaperWireframes space">
                    <div className="text-center">
                        <h2 className="bold p-5">Paper Wireframes</h2>
                        <p>For my initial designs, I created a unique chatbot experience that would address the solutions for the pain points.</p>
                    </div>
                    <div className="row row-cols-1 row-cols-md-1 g-4">
                        <div className="col p-5">
                            <img src={pic5} className="pic5" style={{ width: '100%' }} alt='pic5'></img>
                        </div>
                    </div>
                </div>


                <div className="Wireflows spacebt">
                    <div className="text-center">
                        <h2 className="bold p-5">Wireflows</h2>
                    </div>
                    <div className="row row-cols-1 row-cols-md-1 g-4">
                        <div className="col lo_fi_pic p-5">
                            <img src={pic6} className="pic6" style={{ width: '100%' }} alt='pic6'></img>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-1 g-4">
                        <div className="col hi_fi_pic p-5">
                            <img src={pic8} className="pic8" style={{ width: '100%' }} alt='pic8'></img>
                        </div>
                    </div>
                </div>

                <div className="Mockups spacebt">
                    <div className="text-center">
                        <h2 className="bold p-5">Mockups</h2>
                    </div>
                    <div className="row row-cols-1 row-cols-md-1 g-4">
                        <div className="col mockup_pic p-5">
                            <img src={pic7} className="pic7" style={{ width: '100%' }} alt='pic7'></img>
                        </div>
                    </div>
                </div>

                <div className="Key_Features bg-white space">
                    <div className="text-center">
                        <h1 className="bold p-5">Key Features</h1>
                    </div>
                    <div className="row row-cols-1 row-cols-md-2 g-4 my-5 ">
                        <div className="col feature_padding d-flex align-items-center">
                            <div>
                                <h2 className="bold">Explore</h2>
                                <p>The structure of a chatbot is a linear process by talking to the bot to get information.
                                    It will take time for the user to explore everything by chatting. So the solution is a simple swipe down
                                    with quick access to the menu, popular items, and offers.</p>
                            </div>
                        </div>
                        <div className="col p-5 d-flex justify-content-center">
                            <img src={feature1} className="feature1" style={{ width: '55%' }} alt='feature1'></img>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-2 g-4 my-5">
                        <div className="col feature_padding d-flex align-items-center">
                            <div>
                                <h2 className="bold">Personalize</h2>
                                <p>The bot will personalize your order by giving you recommendations and offers just for you.
                                    As a result, it would be easier for the user to interact with the bot and feel comfortable during the food ordering process.
                                </p>
                            </div>
                        </div>
                        <div className="col p-5">
                            <Carousel fade variant="dark">
                                <Carousel.Item interval={1000} className="d-flex justify-content-center">
                                    <img src={feature2_1} className="feature2_1" style={{ width: '55%' }} alt='feature2_1'></img>

                                </Carousel.Item>
                                <Carousel.Item interval={1000} className="d-flex justify-content-center">
                                    <img src={feature2_2} className="feature2_2" style={{ width: '55%' }} alt='feature2_2'></img>

                                </Carousel.Item>
                                <Carousel.Item className="d-flex justify-content-center">
                                    <img src={feature2_3} className="feature2_3" style={{ width: '55%' }} alt='feature2_3'></img>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-2 g-4 my-5">
                        <div className="col feature_padding d-flex align-items-center">
                            <div>
                                <h2 className="bold">Customize</h2>
                                <p>Users can customize their pizza with few simple steps for a quick carting experience.
                                    The total price is always visible to the users on each customization step.
                                </p>
                            </div>
                        </div>
                        <div className="col p-5">
                            <Carousel fade variant="dark">
                                <Carousel.Item interval={1000} className="d-flex justify-content-center">
                                    <img src={feature3_1} className="feature3_1" style={{ width: '55%' }} alt='feature3_1'></img>

                                </Carousel.Item>
                                <Carousel.Item interval={1000} className="d-flex justify-content-center">
                                    <img src={feature3_2} className="feature3_2" style={{ width: '55%' }} alt='feature3_2'></img>

                                </Carousel.Item>
                                <Carousel.Item className="d-flex justify-content-center">
                                    <img src={feature3_3} className="feature3_3" style={{ width: '55%' }} alt='feature3_3'></img>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>


                <div className="takeawaysES space">
                    <div className="row row-cols-1 row-cols-md-1 g-4 mb-5">
                        <div className="col p-5 d-flex justify-content-center align-items-center">
                            <div>
                                <h1 className="bold text-center">Key Takeaways</h1>

                            </div>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-2 g-4">
                        <div className="col p-5">
                            <h2 className="bold">What I learned</h2>
                            <p>Working on this project was very exciting. Designing a whole new way to order food that would benefit everyone is something that I was eager to do from the start.
                                I also learned a lot about Figma and its amazing prototyping tools. Also, many UX skills in prioritizing the user and improving the overall user experience.
                            </p>
                        </div>
                        <div className="col p-5">
                            <h2 className="bold">Next Steps:</h2>
                            <p>(1) Conduct a few more rounds of usability studies to get more insights and iterate through the designs. </p>
                            <p>(2) Look at a few KPIs to indicate how well the chatbot design is compared to traditional food delivery apps.  </p>
                        </div>
                    </div>
                    <div className="text-center mt-5">
                        <p>Check out my full design process here.</p>
                        <a className="text-decoration-none" target='none' href="https://www.behance.net/gallery/127924787/Esilio-Pizzeria"> <button className="mx-5 deck">Slide Deck</button></a>
                    </div>
                </div>

                <div className="text-center bg-white space">
                    <h2>Let's Connect</h2>
                    <p>Looking for a designer that have skills in development. Reach Out!</p>
                    <div className="d-flex justify-content-center mt-5">
                        <a className="text-decoration-none" href='https://www.linkedin.com/in/alan-eappen-a331571a0/' target='_blank' rel="noreferrer"><i className="fab fa-linkedin mx-2" style={{ color: '#E4572E', fontSize: '200%' }}></i></a>
                        <a className="text-decoration-none" href={'mailto:eappenalan123@gmail.com'} target='_blank' rel="noreferrer"> <i className="far fa-envelope mx-2" style={{ color: '#E4572E', fontSize: '200%' }}></i></a>
                        <a className="text-decoration-none" href='https://www.behance.net/alaneappen' target='_blank' rel="noreferrer"> <i className="fab fa-behance mx-2" style={{ color: '#E4572E', fontSize: '200%' }}></i></a>
                        <a className="text-decoration-none" href='https://github.com/alaneappen514' target='_blank' rel="noreferrer"> <i className="fab fa-github mx-2" style={{ color: '#E4572E', fontSize: '200%' }}></i></a>
                    </div>
                    <div className="spacetp">
                        <div>
                            <h3 className="bold">My Work</h3>
                        </div>
                        <div className="row row-cols-1 row-cols-md-2 g-4 mt-5">
                            <div className="col  d-flex justify-content-center">
                                <Card className="feature_work" style={{ width: '22rem', border: ' none' }}>
                                    <Card.Img style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }} variant="top" src={haunted_decoder} />
                                    <Card.Body style={{ backgroundColor: '#636363', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}>
                                        <Card.Text className="text-white">
                                            Check out a game I made from Unity.<br></br>Try playing it!!
                                        </Card.Text>
                                        <Link to='/haunted_decoder' className="text-decoration-none"> <button className="mx-5 deck">View</button></Link>
                                    </Card.Body>
                                </Card>

                            </div>
                            <div className="col  d-flex justify-content-center">
                                <Card className="feature_work" style={{ width: '22rem', border: ' none' }}>
                                    <Card.Img style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }} variant="top" src={crimson_tech} />
                                    <Card.Body style={{ backgroundColor: '#636363', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}>
                                        <Card.Text className="text-white" >
                                            Rethinking product information for e-commerce tech.
                                        </Card.Text>
                                        <Link to='/crimson_tech' className="text-decoration-none"> <button className="mx-5 deck">View</button></Link>
                                    </Card.Body>
                                </Card>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default EsilioPizzeria
