import React from "react";
import "../components/styles/footer.css";

const Footer = () => {
  return (
    <div>
      <div className="container footer_1">
        <div className="d-flex justify-content-center align-items-center p-5">
          <p className="footertext">Developed by Alan Eappen</p>
          <i
            className="far fa-copyright mb-3 mx-2"
            style={{ color: "red" }}
          ></i>
          <p className="footertext">2022</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
