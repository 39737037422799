import React from "react";
import { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import "../components/styles/h_decode.css";
import pic1 from "../components/Images/HauntedDecoder/HauntedDecoderBanner.png";
import pic2 from "../components/Images/HauntedDecoder/HD1.png";
import pic3 from "../components/Images/HauntedDecoder/Research.png";
import pic4 from "../components/Images/HauntedDecoder/GamesCompetitiveAudit.png";
import pic5 from "../components/Images/HauntedDecoder/game_design.jpg";
import pic6 from "../components/Images/HauntedDecoder/assessment_design.jpg";
import pic7 from "../components/Images/HauntedDecoder/wireframes.jpg";
import pic8 from "../components/Images/HauntedDecoder/userflow.jpg";
import esilio_pizza from "../components/Images/EsilioLogo.png";
import crimson_tech from "../components/Images/CrimsonTech/CT_Logo.png";
import storyMP4 from "../components/Images/HauntedDecoder/story.mp4";
import riddlesMP4 from "../components/Images/HauntedDecoder/riddles.mp4";
import achievementsMP4 from "../components/Images/HauntedDecoder/achievements.mp4";
import conversionMP4 from "../components/Images/HauntedDecoder/conversion_problems.mp4";
import assessmentMP4 from "../components/Images/HauntedDecoder/assessment.mp4";
const HauntedDecoder = () => {
  const [showButton, setShowButton] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="fadeIn">
      {showButton && (
        <span onClick={scrollToTop} className="back-to-top">
          &#8679;
        </span>
      )}
      <div className="heroBanner my-3">
        <div>
          <img src={pic1} className="bg" alt="pic1"></img>
        </div>
        <div className="testBanner">
          <div className="overlayText d-flex justify-content-between align-items-center">
            <h5 className="p-5 mx-3">
              <span className="bold">Role:</span> Interaction, Visual Designer /
              Game Designer / C# Programmer
            </h5>
            <h5 className="p-5 mx-3">
              <span className="bold">Duration:</span> October 2019 - March 2021
            </h5>
          </div>
        </div>
      </div>
      <div>
        <div className="container">
          <div className=" roleText row row-cols-1 row-cols-md-2 g-4 mt-5">
            <div className="col d-flex justify-content-center align-items-center p-5">
              <h5>
                <span className="bold">Role:</span> Interaction, Visual Designer
                / Game Designer / C# Programmer
              </h5>
            </div>
            <div className="col d-flex justify-content-center align-items-center p-5">
              <h5>
                <span className="bold">Duration:</span> October 2019 - March
                2021
              </h5>
            </div>
          </div>
          <div className="overview spacebt mt-5">
            <div className="row row-cols-1 row-cols-md-2 g-4">
              <div className="col p-5">
                <h2 className="bold">Game Overview</h2>
                <p>
                  Haunted Decoder is an educational game that teaches players
                  binary conversions. Its goal is to give students fun
                  alternative study material for assessments or projects. Modern
                  educational games feel repetitive and not fun. Players lose
                  engagement and quit playing the game. Haunted Decoder aims to
                  fix this by bringing in fun game mechanics to keep players
                  playing and at the same time learn an academic concept.
                </p>
              </div>
              <div className="col p-5">
                <h2 className="bold">Key Points</h2>
                <p>
                  <span className="bold">(1)</span> Establish an immersive
                  gaming experience
                </p>
                <p>
                  <span className="bold">(2)</span> Create an intuitive
                  assessment design.
                </p>
              </div>
            </div>

            <div className=" d-flex justify-content-center align-items-center my-5">
              <img
                src={pic2}
                className="pic2"
                style={{ width: "50%" }}
                alt="pic2"
              ></img>
            </div>
          </div>

          <div className="container">
            <div className="research space bg-white">
              <div className="row row-cols-1 row-cols-md-2 g-4">
                <div className="col p-5">
                  <h2 className="bold">Research</h2>
                  <p>
                    Before attempting to create a game in Unity, I did some
                    research and asked myself a few questions. How are
                    educational games keeping players engaged? Are they learning
                    from the content that’s provided? What do educational games
                    lack that modern games have? What makes modern games fun to
                    play? These questions help me understand what educational
                    games need to boost engagement and also help me brainstorm
                    ideas on game design.
                  </p>
                </div>
                <div className="col">
                  <div className=" d-flex justify-content-center align-items-center">
                    <img src={pic3} style={{ width: "100%" }} alt="pic3"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="competitive_analysis space">
            <div className="row row-cols-1 row-cols-md-2 g-4">
              <div className="col p-5">
                <h2 className="bold">Competitive Analysis</h2>
                <p>
                  I researched a few games to see their effectiveness in
                  providing a good educational gaming experience. I evaluated
                  several features and compared them with each competitor. I
                  utilized this information to see what user goals they were not
                  achieving.
                </p>
              </div>
              <div className="col p-5">
                <h2 className="bold">Overview</h2>
                <p>
                  <span className="bold">(1)</span> I've seen little to no
                  engagement on most of the binary games. Ozaria, a coding game
                  has an interesting story that kept me playing.
                </p>
                <p>
                  <span className="bold">(2)</span> A few games had poor UI that
                  made it confusing to play and not approachable.
                </p>
                <p>
                  <span className="bold">(3)</span> A score system or getting
                  achievements felt rewarding.
                </p>
                <p>
                  <span className="bold">(4)</span> Most of the games didn't
                  provide a variety of concepts to learn.
                </p>
              </div>
            </div>
            <div className=" d-flex justify-content-center align-items-center my-5">
              <img
                src={pic4}
                className="pic4"
                style={{ width: "75%" }}
                alt="pic4"
              ></img>
            </div>
          </div>

          <div className="p_p_wireframe bg-white spacetp">
            <div className="row row-cols-1 row-cols-md-1 g-4">
              <div className="col p-5 d-flex justify-content-center align-items-center">
                <div>
                  <h2 className="bold">Paper Wireframes </h2>
                  <p>
                    {" "}
                    To get the basic idea of the game design and assessment
                    design,<br></br>I did some sketches to evaluate the core
                    concepts and game mechanics.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-5 text-center">
              <div>
                <h1>Game Design</h1>
                <img
                  src={pic5}
                  className="pic5"
                  style={{ width: "85%" }}
                  alt="pic5"
                ></img>
              </div>
            </div>
            <div className="p-5 text-center">
              <div>
                <h1>Assessment Design</h1>
                <img
                  src={pic6}
                  className="pic6"
                  style={{ width: "85%" }}
                  alt="pic6"
                ></img>
              </div>
            </div>
          </div>

          <div className="wireflow space">
            <div className="row row-cols-1 row-cols-md-1 g-4">
              <div className="col p-5 d-flex justify-content-center align-items-center">
                <div>
                  <h2 className="bold">Game User Flow & Wireflow</h2>
                  <p>
                    {" "}
                    The user flow illustrate the journey the players take to
                    complete the game.<br></br>
                    The wireflow help visualize the interactions and screen
                    flow.
                  </p>
                </div>
              </div>
            </div>
            <div className=" d-flex justify-content-center align-items-center my-5">
              <img
                src={pic8}
                className="pic8"
                style={{ width: "75%" }}
                alt="pic8"
              ></img>
            </div>
            <div className=" d-flex justify-content-center align-items-center my-5">
              <img
                src={pic7}
                className="pic7"
                style={{ width: "100%" }}
                alt="pic7"
              ></img>
            </div>
          </div>

          <div className="solutions bg-white space">
            <div className="row row-cols-1 row-cols-md-1 g-4 mb-5">
              <div className="col p-5 d-flex justify-content-center align-items-center">
                <div>
                  <h1 className="bold text-center">Final Prototype</h1>
                  <h5 className="bold text-center">
                    {" "}
                    (1) Establish an immersive gaming experience
                  </h5>
                </div>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 g-4 spacebt">
              <div className="col p-5">
                <h2 className="bold">Story</h2>
                <p>
                  {" "}
                  I want the players to get immersed in the game by showcasing
                  them an in-depth story. Telling a story feels a sense of
                  curiosity and excitement when you start the game. I don't want
                  them to begin with a question right away. I want players to
                  get a feel for the setting, plot, and characters as they begin
                  their journey in learning something new.
                </p>
              </div>
              <div className="col p-5">
                <video
                  playsInline="playsinline"
                  autoPlay="autoplay"
                  muted
                  loop="loop"
                >
                  <source src={storyMP4} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 g-4 spacebt">
              <div className="col p-5">
                <h2 className="bold">Riddles</h2>
                <p>
                  {" "}
                  As players answer binary questions, they get to decode
                  messages. They will uncover a riddle that would lead them to
                  victory. This feature is a fun aspect of the overall decoding
                  process. Players get to be in the shoes of a detective as they
                  play through the game.
                </p>
              </div>
              <div className="col p-5">
                <video
                  playsInline="playsinline"
                  autoPlay="autoplay"
                  muted
                  loop="loop"
                >
                  <source src={riddlesMP4} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>

            <div className="row row-cols-1 row-cols-md-2 g-4 spacebt">
              <div className="col p-5">
                <h2 className="bold">Achievements</h2>
                <p>
                  The game offers a traditional score system where players
                  advance each level and score points. There are also
                  achievements where players answer conversion questions and get
                  specific achievements based on the conversion type. I want
                  players to have a good experience solving questions.
                  Achievements would give them the motivation and the confidence
                  they need to tackle many problems.
                </p>
              </div>
              <div className="col p-5">
                <video
                  playsInline="playsinline"
                  autoPlay="autoplay"
                  muted
                  loop="loop"
                >
                  <source src={achievementsMP4} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>

            <div className="row row-cols-1 row-cols-md-1 g-4 mb-5">
              <div className="col p-5 d-flex justify-content-center align-items-center">
                <div>
                  <h5 className="bold text-center">
                    {" "}
                    (2) Create an intuitive assessment design.
                  </h5>
                </div>
              </div>
            </div>

            <div className="row row-cols-1 row-cols-md-2 g-4 spacebt">
              <div className="col p-5">
                <h2 className="bold">Multiple Problem Types</h2>
                <p>
                  The games I played in my competitive analysis only focused on
                  one or two conversion types. I tried to fix this by including
                  all types decimal, binary, octal, and hex, in which players
                  get exposed to a variety of conversion problems.
                </p>
              </div>
              <div className="col p-5">
                <video
                  playsInline="playsinline"
                  autoPlay="autoplay"
                  muted
                  loop="loop"
                >
                  <source src={conversionMP4} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 g-4">
              <div className="col p-5">
                <h2 className="bold">Interactive Assessment Learning</h2>
                <p>
                  One of my biggest goals was to create a unique approach in
                  teaching binary conversions to players. I made a design where
                  players can toggle between 1s and 0s that depict binary values
                  that help find the solution. I also added examples to clarify
                  the procedure in answering a variety of conversion problems. I
                  want players to learn as they interact with the design that
                  will lead them to the answer.
                </p>
              </div>
              <div className="col p-5">
                <video
                  playsInline="playsinline"
                  autoPlay="autoplay"
                  muted
                  loop="loop"
                >
                  <source src={assessmentMP4} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>

          <div className="takeaways space">
            <div className="row row-cols-1 row-cols-md-1 g-4 mb-5">
              <div className="col p-5 d-flex justify-content-center align-items-center">
                <div>
                  <h1 className="bold text-center">Key Takeaways</h1>
                </div>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 g-4">
              <div className="col p-5">
                <h2 className="bold">Impact</h2>
                <p>
                  I'm confident this game is a good contender in the educational
                  gaming platform since it solved many problems. Emphasis on
                  engagement, assessment learning, and how presentable it is to
                  the player are all the components added into the game. This
                  game would benefit students who are taking intro classes to
                  computer science.
                </p>
              </div>
              <div className="col p-5">
                <h2 className="bold">What I learned:</h2>
                <p>
                  As my first attempt at creating a game, I learned a lot from
                  design to implementation. This project helped me introduce
                  Adobe XD. I learned quite a few design skills that helped
                  prototype the assessment designs. I also learned more about
                  Unity 2D. Creating animations through sprite sheets, Unity UI,
                  C# scripting, and singletons were a few things I learned that
                  helped me implement the game.{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="row row-cols-1 row-cols-md-1 g-4 spacebt">
            <div className="col  d-flex justify-content-center align-items-center">
              <div>
                <h5 className="bold text-center">
                  Now, Let's investigate the land of Galldari :)
                </h5>
              </div>
            </div>
            <div className="col  d-flex justify-content-center align-items-center">
              <div>
                <a
                  href="https://haunted-decoder-game.netlify.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="bold playbtn">Play Game</button>
                </a>
              </div>
            </div>
          </div>

          <div className="text-center bg-white space">
            <h2>Let's Connect</h2>
            <p>
              Looking for a designer that have skills in development. Reach Out!
            </p>
            <div className="d-flex justify-content-center mt-5">
              <a
                className="text-decoration-none"
                href="https://www.linkedin.com/in/alan-eappen-a331571a0/"
                target="_blank"
                rel="noreferrer"
              >
                <i
                  className="fab fa-linkedin mx-2"
                  style={{ color: "#62f600", fontSize: "200%" }}
                ></i>
              </a>
              <a
                className="text-decoration-none"
                href={"mailto:eappenalan123@gmail.com"}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <i
                  className="far fa-envelope mx-2"
                  style={{ color: "#62f600", fontSize: "200%" }}
                ></i>
              </a>
              <a
                className="text-decoration-none"
                href="https://www.behance.net/alaneappen"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <i
                  className="fab fa-behance mx-2"
                  style={{ color: "#62f600", fontSize: "200%" }}
                ></i>
              </a>
              <a
                className="text-decoration-none"
                href="https://github.com/alaneappen514"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <i
                  className="fab fa-github mx-2"
                  style={{ color: "#62f600", fontSize: "200%" }}
                ></i>
              </a>
            </div>
            <div className="spacetp">
              <div>
                <h3 className="bold">My Work</h3>
              </div>
              <div className="row row-cols-1 row-cols-md-2 g-4 mt-5">
                <div className="col  d-flex justify-content-center">
                  <Card
                    className="feature_work"
                    style={{ width: "22rem", border: " none" }}
                  >
                    <Card.Img
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                      variant="top"
                      src={esilio_pizza}
                    />
                    <Card.Body
                      style={{
                        backgroundColor: "black",
                        borderBottomLeftRadius: "20px",
                        borderBottomRightRadius: "20px",
                      }}
                    >
                      <Card.Text className="text-white">
                        Have you ordered Pizza from a chatbot? Check it out.
                      </Card.Text>
                      <Link
                        to="/esilio_pizzeria"
                        className="text-decoration-none"
                      >
                        {" "}
                        <button className="mx-5 view1">View</button>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col  d-flex justify-content-center">
                  <Card
                    className="feature_work"
                    style={{ width: "22rem", border: " none" }}
                  >
                    <Card.Img
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                      variant="top"
                      src={crimson_tech}
                    />
                    <Card.Body
                      style={{
                        backgroundColor: "black",
                        borderBottomLeftRadius: "20px",
                        borderBottomRightRadius: "20px",
                      }}
                    >
                      <Card.Text className="text-white">
                        Rethinking product information for e-commerce tech.
                      </Card.Text>
                      <Link to="/crimson_tech" className="text-decoration-none">
                        {" "}
                        <button className="mx-5 view1">View</button>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HauntedDecoder;
