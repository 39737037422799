import React from "react";
import { Link } from "react-router-dom";
import "../components/styles/home.css";
import pc1 from "../components/Images/HauntedDecoderLogo.png";
import pc2 from "../components/Images/EsilioLogo.png";
import pc3 from "../components/Images/Crimson_TechLogo.png";
import pc4 from "../components/Images/GameCord.png";
import Typewriter from "typewriter-effect";
const Home = () => {
  return (
    <div className="fadeIn">
      <div className="container">
        <div className="row">
          <div className="col-sm">
            <div
              className="dropdown-divider line1 mt-5"
              style={{ borderColor: "red", borderTopWidth: "3px" }}
            ></div>
          </div>
          <div className="col-sm">
            <div className="d-flex flex-column  justify-content-center align-items-center my-2">
              <h3 className="titleText">
                Hi I'm{" "}
                <span style={{ fontWeight: 900, fontSize: "35px" }}>Alan</span>,
              </h3>
              <h3 className="mb-4 titleText" style={{ color: "red" }}>
                <Typewriter
                  options={{
                    autoStart: true,
                    loop: true,
                  }}
                  onInit={(typewriter) => {
                    typewriter
                      .typeString("UI/UX Designer")
                      .pauseFor(1200)
                      .deleteAll()
                      .typeString("Web Developer")
                      .pauseFor(1200)
                      .deleteAll()
                      .typeString("Game Designer")
                      .pauseFor(1200)
                      .deleteAll()
                      .start();
                  }}
                />
              </h3>
            </div>
          </div>
          <div className="col-sm">
            <div
              className="dropdown-divider line2 mt-5"
              style={{ borderColor: "red", borderTopWidth: "3px" }}
            ></div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row row-cols-1 row-cols-md-2 g-4">
          <div className="col">
            <Link to="/gamecord" className="text-decoration-none">
              <div className="card border-0">
                <img
                  src={pc4}
                  className="card-img-top"
                  alt="project"
                  style={{
                    borderRadius: "25px",
                    height: "25vw",
                    objectFit: "cover",
                  }}
                ></img>
                <div className="overlay">
                  <div className="text">
                    <h2 className="cardText">GameCord</h2>
                    <h5 className="cardText">Web App</h5>
                    <div className="container">
                      <div
                        className="dropdown-divider line_card mt-5"
                        style={{ borderColor: "white", borderTopWidth: "3px" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link to="/haunted_decoder" className="text-decoration-none">
              <div className="card border-0">
                <img
                  src={pc1}
                  className="card-img-top"
                  alt="project"
                  style={{
                    borderRadius: "25px",
                    height: "25vw",
                    objectFit: "cover",
                  }}
                ></img>
                <div className="overlay">
                  <div className="text">
                    <h2 className="cardText">Haunted Decoder</h2>
                    <h5 className="cardText">Game UI/UX </h5>
                    <div className="container">
                      <div
                        className="dropdown-divider line_card mt-5"
                        style={{ borderColor: "white", borderTopWidth: "3px" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link to="/crimson_tech" className="text-decoration-none">
              <div className="card border-0">
                <img
                  src={pc3}
                  className="card-img-top"
                  alt="project"
                  style={{
                    borderRadius: "25px",
                    height: "25vw",
                    objectFit: "cover",
                  }}
                ></img>
                <div className="overlay">
                  <div className="text">
                    <h2 className="cardText">Crimson Tech</h2>
                    <h5 className="cardText"> Website UI/UX</h5>
                    <div className="container">
                      <div
                        className="dropdown-divider line_card mt-5"
                        style={{ borderColor: "white", borderTopWidth: "3px" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link to="/esilio_pizzeria" className="text-decoration-none">
              <div className="card border-0">
                <img
                  src={pc2}
                  className="card-img-top"
                  alt="project"
                  style={{
                    borderRadius: "25px",
                    height: "25vw",
                    objectFit: "cover",
                  }}
                ></img>
                <div className="overlay">
                  <div className="text">
                    <h2 className="cardText">Esilio Pizzeria</h2>
                    <h5 className="cardText"> Mobile UI/UX </h5>
                    <div className="container">
                      <div
                        className="dropdown-divider line_card mt-5"
                        style={{ borderColor: "white", borderTopWidth: "3px" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
