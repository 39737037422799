import React from "react";
import pic1 from "../components/Images/Gamecord/banner.jpg";
import pic2 from "../components/Images/Gamecord/gamecord.png";
import "../components/styles/gamecord.css";
const Gamecord = () => {
  return (
    <div>
      <div className="heroBanner my-3">
        <div>
          <img src={pic1} className="bg" alt="pic1"></img>
        </div>
        <div className="testBanner">
          <div className="overlayText d-flex justify-content-between align-items-center">
            <h5 className="p-5 mx-3">
              <span className="bold">Role:</span> Frontend Developer
            </h5>
            <h5 className="p-5 mx-3">
              <span className="bold">Duration:</span> June 2020
            </h5>
            <a
              className="text-decoration-none"
              target="none"
              href="https://github.com/mohammedothman7/capstone-client"
            >
              {" "}
              <button className="mx-5 deckCT">GitHub</button>
            </a>
          </div>
        </div>
      </div>
      <div className="container">
        <div className=" roleText row row-cols-1 row-cols-md-2 g-4 mt-5">
          <div className="col d-flex justify-content-center align-items-center p-5">
            <h5>
              <span className="bold">Role:</span> Frontend Developer
            </h5>
          </div>
          <div className="col d-flex justify-content-center align-items-center p-5">
            <h5>
              <span className="bold">Duration:</span> June 2020
            </h5>
          </div>
        </div>
        <div className="spacetp p-5">
          <h1>GameCord</h1>
          <p>
            GameCord is a Full-Stack Game Discovery Web app built for the Summer
            2020 Tech Talent Pipeline bootcamp. Built with{" "}
            <span style={{ fontWeight: "800px" }}>
              React, Redux, Node, Express, Sequelize, PostgreSQL and other
              supported packages.
            </span>
          </p>
        </div>
        <div className="text-center spacebt">
          <img className="gamecordImg " src={pic2} alt="gamecordImg"></img>
          <a
            className="text-decoration-none"
            target="none"
            href="https://gamecord.netlify.app/"
          >
            {" "}
            <button className="mx-5 deckCT mt-5">Launch app</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Gamecord;
