import React from 'react'
import "../components/styles/about.css"
import ProfilePic from "../components/Images/ProfilePic.png"


const About = () => {
    return (
        <div className="container_A container">
            <div className="row row-cols-1 row-cols-md-2 g-4 aboutContainer">
                <div className="col">
                    <div className="card border-0" style={{ backgroundColor: 'whitesmoke' }}>
                        <div className="d-flex">
                            <div className="photo">
                                <div>
                                    <img src={ProfilePic} alt="profile" style={{ borderRadius: '20px', height: 'auto', width: '100%' }}></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card border-0" style={{ backgroundColor: 'whitesmoke' }}>
                        <div>
                            <h2>Nice to meet <span style={{ color: 'red' }}>YOU</span>,</h2>
                            <br></br>
                            <p>
                                I'm a passionate designer that loves to create unique experiences.
                                My love for design started with playing video games and frontend development.
                                I'm always curious to see how a product functions. I closely look at the visuals and interactions to see the impact it would have.
                                My favorite thing about design is creativity is endless. It's like a bubble filled with ideas to shape the future.
                                I strive to look for that idea that challenges me the most.
                            </p>
                            <br></br>
                            <p>~ Alan Eappen</p>
                            <br></br>
                            <div className="d-flex">
                                <a className="text-decoration-none" href='https://www.linkedin.com/in/alan-eappen-a331571a0/' target='_blank' rel="noreferrer"><i className="fab fa-linkedin mx-3" style={{ color: 'red', fontSize: '200%' }}></i></a>
                                <a className="text-decoration-none" href={'mailto:eappenalan123@gmail.com'} target='_blank' rel="noreferrer"> <i className="far fa-envelope mx-3" style={{ color: 'red', fontSize: '200%' }}></i></a>
                                <a className="text-decoration-none" href='https://www.behance.net/alaneappen' target='_blank' rel="noreferrer"> <i className="fab fa-behance mx-3 " style={{ color: 'red', fontSize: '200%' }}></i></a>
                                <a className="text-decoration-none" href='https://github.com/alaneappen514' target='_blank' rel="noreferrer"> <i class="fab fa-github mx-3" style={{ color: 'red', fontSize: '200%' }}></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default About;
